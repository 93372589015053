<template>
  <div>
    <!-- <figure v-lazyload> 
    <img class="image__item" :data-url="source" loading="lazy" alt />
     <img class="image__item" v-else :data-url="source" loading="lazy" alt /> -->
  <!-- </figure> -->
  <figure>
    <img class="image__item" :src="source" loading="lazy" :alt="metaTitle" :title="metaTitle"/>
  </figure>
  </div>
 
</template>

<script>
import LazyLoad from "../../directives/lazyLoad.js";

export default {
  name: "imageItem",
  props: ['source', 'metaTitle'],
  data() {
    return {
      posterChange: true,
    };
  },
  watch: {
    source(val) {
      if (val) {
        this.posterChange = !this.posterChange;
      }
    },
  },
  directives: {
    lazyload: LazyLoad,
  },
};
</script>

<style lang="scss" scoped>
@import "./imageItem.scss"
</style>
